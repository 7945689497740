/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../../store/actions/mobileTableActions';
import AmountBox from '../../../components/amountBox';
import StateIndicator from '../../../components/stateIndicator/stateIndicator';
import cirusLogoSquare from '../../../images/cirusLogoSquare.png';
import metamaskIcon from '../../../images/metamaskIcon.png';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import StakingContract from '../../../contracts/abis/NewStakingContract.json';
import SignTransactionModal from '../../../components/signTransactionModal/signTransactionModal';
import TransactionSuccessModal from '../../../components/transactionSuccessModal/transactionSuccessModal';
import LoadingModal from '../../../components/loadingModal/loadingModal';
import liquidIcon from '../../../images/Cirus Dash Icons/2LiquidBalance@4x.png';
import stakedIcon from '../../../images/Cirus Dash Icons/3StakedBalance@4x.png';
import rewardIcon from '../../../images/Cirus Dash Icons/4RewardBalance@4x.png';
import cirusIcon from '../../../images/cirusToken.png';
import { TopTable } from '../../../components/tables/topTable';
import CirusToken from '../../../contracts/abis/CirusToken.json';
import './cirusStakinView.scss';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { Modal } from '../../../components/modals/modal';

const UseFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
// eslint-disable-next-line react/prop-types
const CirusStakingView = (props) => {
  const { balances } = props;

  const { account, library } = useWeb3React();
  // eslint-disable-next-line react/prop-types
  const {
    stakedContract,
    stakedContractUSD,
    rewards,
    rewardsUSD,
    liquid,
    liquidUSD,
    stakingContractTotalBalanceUSD,
  } = balances;
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [stakerDropDownOpen, setStakerDropDownOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [contractApproved, setContractApproved] = useState(false);
  // drop down selectors
  const [action, setAction] = useState();
  const [stakerAction, setStakerAction] = useState();
  // Modal Active
  const [isSignTransactionModalActive, setIsSignTransactionModalActive] =
    useState(false);
  const [isTransactionSuccessModalActive, setIsTransactionSuccessModalActive] =
    useState(false);
  const [input1Ref, setInput1Focus] = UseFocus();
  // form value for action
  const [amountToStake, setAmountToStake] = useState(0);
  const [transactionError, setTransactionError] = useState();
  const [loading, setLoading] = useState(false);
  const [stakedContract_S, setStakedContract] = useState(0);
  const [rewards_S, setReward] = useState(0);
  const [transactionHash, setTransactionHash] = useState('');
  const [graphData, setGraphData] = useState();
  const [receivedTransactionError, setReceivedTransactionError] = useState('');
  const [addedContribution, setAddedContribution] = useState(0);
  const rateUSD = liquidUSD / liquid;

  const selectAction = (selectedAction) => {
    setAction(selectedAction);
    if (selectedAction == 'Claim Reward') {
      let toStake = rewards_S.toString();
      setAmountToStake(toStake);
    }
    if (selectedAction == 'Unstake') {
      let toStake = stakedContract_S.toString();
      setAmountToStake(toStake);
    }
    setDropDownOpen(false);
    setInput1Focus();
  };

  const toggleTransactionModalActive = () => {
    if (!action) {
      setTransactionError('Please select an action');
      return;
    }
    if (parseFloat(amountToStake) <= 0) {
      setTransactionError('Amount has to be greater than zero');
      return;
    }
    if (action === 'stake') {
      if (parseFloat(amountToStake) > parseFloat(liquid)) {
        setTransactionError(
          'Insufficient balance. Please confirm the amount you want to stake',
        );
        return;
      }
    }
    if (
      action === 'Unstake' ||
      action === 'Full Unstake' ||
      action === 'Unstake and claim rewards'
    ) {
      if (parseFloat(amountToStake) > parseFloat(stakedContract_S)) {
        setTransactionError(
          'Insufficient balance. Please confirm the amount you want to untake',
        );
        return;
      }
    }
    setIsSignTransactionModalActive(true);
  };

  const selectStakerAction = (selectedStakerAction) => {
    setStakerAction(selectedStakerAction);
    setStakerDropDownOpen(false);
  };

  const setAmount = (percentage) => {
    if (!action) return;
    let amount =
      action == 'stake'
        ? liquid
        : action == 'Claim Reward'
        ? rewards_S
        : stakedContract_S;
    let toStake = ((percentage / 100) * amount).toString();
    setAmountToStake(toStake);
  };

  const getContract = async (instance, library, address) => {
    const abi = instance.abi;
    const contract = new ethers.Contract(address, abi, library.getSigner());
    return { contract, address };
  };

  const preApprove = async () => {
    if (amountToStake <= 0) {
      return;
    }

    try {
      let CirusTokenContract = await getContract(
        CirusToken,
        library,
        process.env.REACT_APP_CIRUS_TOKEN_ETHEREUM,
      );

      setIsApproving(true);

      await CirusTokenContract.contract.functions
        .approve(
          process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM,
          ethers.utils.parseEther(amountToStake.toString()).toString(),
        )
        .then((result) => {
          CirusTokenContract.contract.on(
            'Approval',
            (owner, spender, amount) => {
              if (account == owner) {
                setIsApproving(false);
                setContractApproved(true);
              }
            },
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTransactionErrors = (err) => {
    if (err.code === 'ACTION_REJECTED') return;
    let errorMatch = err.toString().match(/reason="([^"]+)"/);
    const currentError =
      errorMatch && errorMatch.length > 1
        ? errorMatch[1]
        : 'It seems like something went wrong. Please contact our support team to report the error.';
    setReceivedTransactionError(currentError);
  };

  // form action functions
  const stake = async () => {
    if (amountToStake <= 0) {
      setTransactionError('Amount has to be greater than zero');
      return;
    }

    if (!action) {
      setTransactionError('Select an action');
      return;
    }

    setIsSignTransactionModalActive(false);
    setLoading(true);

    const stakingContractData = await getContract(
      StakingContract,
      library,
      process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM,
    );

    try {
      await stakingContractData.contract.functions
        .deposit(
          ethers.utils.parseEther((amountToStake - 0.01).toString()).toString(),
        )
        .then((result) => {
          setTransactionHash(result.hash);
          stakingContractData.contract.on('Staked', (staker, amount) => {
            if (account == staker) {
              setLoading(false);
              setIsTransactionSuccessModalActive(true);
              getNewData();
            }
          });
        });
    } catch (err) {
      handleTransactionErrors(err);
      setLoading(false);
    }
  };
  const getNewData = async () => {
    if (library) {
      const stakingContractData = await getContract(
        StakingContract,
        library,
        process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM,
      );

      let cirusStakingContractStakedBalance = (
        await stakingContractData.contract.functions.stakeHistory(account)
      )[0];
      cirusStakingContractStakedBalance = parseFloat(
        cirusStakingContractStakedBalance / 10 ** 18,
      ).toFixed(2);

      setStakedContract(cirusStakingContractStakedBalance);

      let cirusStakingRewardsBalance =
        (
          await stakingContractData.contract.functions.pendingReward(account)
        )[0] /
        10 ** 18;
      cirusStakingRewardsBalance = parseFloat(
        cirusStakingRewardsBalance,
      ).toFixed(2);

      setReward(cirusStakingRewardsBalance);
    }
  };
  const withdraw = async () => {
    if (!action) {
      setTransactionError('Select an action');
      return;
    }

    setIsSignTransactionModalActive(false);
    setLoading(true);

    const stakingContractData = await getContract(
      StakingContract,
      library,
      process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM,
    );

    try {
      let gasPrice = (await library.getFeeData()).gasPrice;
      if (action == 'Unstake') {
        if (amountToStake <= 0) {
          setTransactionError('Amount has to be greater than zero');
          return;
        }
        await stakingContractData.contract.functions
          .withdraw(ethers.utils.parseEther(amountToStake).toString())
          .then((result) => {
            setTransactionHash(result.hash);
            stakingContractData.contract.on(
              'Withdrawed',
              (withdrawer, amount) => {
                if (account == withdrawer) {
                  setLoading(false);
                  setIsTransactionSuccessModalActive(true);
                  getNewData();
                }
              },
            );
          });
      } else if (action == 'Unstake and claim rewards') {
        if (amountToStake <= 0) {
          setTransactionError('Amount has to be greater than zero');
          return;
        }
        await stakingContractData.contract.functions
          .withdraw(ethers.utils.parseEther(amountToStake).toString())
          .then((result) => {
            setTransactionHash(result.hash);
            stakingContractData.contract.on(
              'Withdrawed',
              (withdrawer, amount) => {
                if (account == withdrawer) {
                  setLoading(false);
                  setIsTransactionSuccessModalActive(true);
                  getNewData();
                }
              },
            );
          });
      } else if (action == 'Claim Reward') {
        await stakingContractData.contract.functions
          .getReward()
          .then((result) => {
            setTransactionHash(result.hash);
            stakingContractData.contract.on(
              'RedeemReward',
              (withdrawer, amount) => {
                if (account == withdrawer) {
                  setLoading(false);
                  getNewData();
                  setIsTransactionSuccessModalActive(true);
                }
              },
            );
          });
      }
    } catch (err) {
      handleTransactionErrors(err);
      setLoading(false);
    }
  };

  const init = async () => {
    if (library) {
      let CirusTokenContract = await getContract(
        CirusToken,
        library,
        process.env.REACT_APP_CIRUS_TOKEN_ETHEREUM,
      );
      try {
        await CirusTokenContract.contract.functions
          .allowance(account, process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM)
          .then((result) => {
            let allowance = parseFloat(
              ethers.utils.formatEther(result[0].toString()),
            );
            setContractApproved(allowance >= amountToStake);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    init();
    const interval = setInterval(() => {
      getNewData();
    }, 3500);
    return () => clearInterval(interval);
  }, [library, amountToStake]);

  useEffect(() => {
    setStakedContract(stakedContract);
    setReward(rewards);
  }, [stakedContract, rewards]);

  useEffect(() => {
    props.setBreadcrumbs([
      { name: 'Staking', link: '/staking' },
      { name: 'Cirus Staking' },
    ]);
    getGraph();
    // calculateContribution();
    // getGraph();
    // calculateContribution();
  }, []);

  const queryClaimer = `
    query {
      stakeds(
        where: {
          staker:"${account}"
        }
      ){
    amount
      }
    }
    `;

  const APIURL = process.env.REACT_APP_THE_GRAPH_API_URL;

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  async function getGraph() {
    client
      .query({
        query: gql(queryClaimer),
      })
      .then((data) => {
        setGraphData(data.data);
        let newVar = 0;
        const gdata = data.data;
        const search = gdata ? gdata.stakeds.length : 0;

        for (let i = 0; i < search; i++) {
          newVar += parseFloat(gdata.stakeds[i].amount) / 10 ** 18;
        }
        setAddedContribution(newVar);
      })
      .catch((err) => {
        console.log('Error fetching data: ', err);
      });
  }

  // async function calculateContribution() {
  //   let newVar = 0;
  //   console.log('en calculateContribution');
  //   console.log('graphData:', graphData);
  //   const search = graphData ? graphData.stakeds.length : 0;

  //   for (let i = 0; i < search; i++) {
  //     console.log('graphData.stakeds[i]:', graphData.stakeds[i]);
  //     newVar += parseFloat(graphData.stakeds[i].amount) / 10 ** 18;
  //   }
  //   setAddedContribution(newVar);
  // }

  return (
    <>
      <section className="section community-staking">
        <div className="container main-container mt-0">
          <TopTable />
          <div className="columns card-container community-staking">
            <div className="column">
              <div className="card has-no-horizontal-padding">
                <div className="card-content pb-0">
                  <div className="columns rpl-6 rpr-6">
                    <div className="column is-narrow mobile-alignment title-image-container">
                      <div className="box p-0 title-image">
                        <figure className="is-inline-block title-image">
                          <img
                            className="title-image"
                            src={cirusLogoSquare}
                            alt="Cirus Logo"
                          />
                        </figure>
                      </div>
                    </div>
                    <div className="column mobile-alignment mt-3">
                      <StateIndicator
                        state="is-active"
                        classNames={'dull subtitle'}
                      >
                        Active
                      </StateIndicator>
                      <p className="title is-2 mt-3 mb-4">
                        Cirus Community Staking
                      </p>

                      <div className="columns is-centered cirus-staking-statistics m-0">
                        <div className="column mobile-alignment mobile-staking is-narrow">
                          <AmountBox
                            topTxt="Minimum stake required"
                            middleTxt="No Minimum"
                          />
                        </div>
                        <div className="column has-text-centered-mobile mobile-staking">
                          <AmountBox
                            topTxt="Total value of staked tokens"
                            middleTxt={`$${new Intl.NumberFormat().format(
                              stakingContractTotalBalanceUSD,
                            )} USD`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column rpt-6 mt-4 has-text-right has-text-centered-mobile">
                      <button
                        className="button is-primary is-large is-rounded"
                        onClick={(e) => selectAction('stake')}
                      >
                        {stakedContract_S > 0 ? 'Increase Stake' : 'Stake'}
                      </button>
                    </div>
                  </div>
                  <div className="columns secondary-staking-statistics">
                    <div className="column level stat-tile">
                      <div className="columns level is-mobile m-0">
                        <div className="level-item level-left">
                          <AmountBox
                            topTxt="Staked Balance"
                            middleTxt={`${new Intl.NumberFormat().format(
                              stakedContract_S,
                            )} CIRUS`}
                            bottomTxt={`$${stakedContractUSD} USD`}
                            avatar={stakedIcon}
                            isIconMobile={true}
                          />
                        </div>
                        <div className="level-item level-right pl-0 mt-0 ml-0 is-hidden-touch">
                          {stakedContract_S > 0 ? (
                            <button
                              className="staking community-action button is-outlined is-primary is-rounded"
                              onClick={(e) => selectAction('Unstake')}
                            >
                              Unstake
                            </button>
                          ) : (
                            <button
                              className="staking community-action button is-outlined is-primary is-rounded"
                              onClick={(e) => selectAction('stake')}
                            >
                              Stake
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="column level stat-tile">
                      <div className="columns level is-mobile m-0">
                        <div className="level-item level-left">
                          <AmountBox
                            topTxt="Rewards Earned"
                            middleTxt={`${new Intl.NumberFormat().format(
                              rewards_S,
                            )} CIRUS`}
                            bottomTxt={`$${rewardsUSD} USD`}
                            avatar={rewardIcon}
                            isIconMobile={true}
                          />
                        </div>
                        <div className="level-item level-right pl-0 mt-0 ml-0 is-hidden-touch">
                          <button
                            className="staking community-action button is-outlined is-primary is-rounded"
                            onClick={(e) => selectAction('Claim Reward')}
                          >
                            Claim
                          </button>
                        </div>
                        {/*<div className="level-item is-narrow level-right pl-0 mt-0 ml-0 is-hidden-touch">
                          <button className="staking community-action button is-outlined is-primary is-rounded">
                            Restake
                          </button>
                          </div>*/}
                      </div>
                    </div>
                    <div className="column level stat-tile">
                      <div className="columns level is-mobile m-0">
                        <div className="level-item level-left">
                          <AmountBox
                            topTxt="Total Contributed"
                            middleTxt={`${new Intl.NumberFormat().format(
                              (addedContribution / 0.85) * 0.15,
                            )} CIRUS`}
                            bottomTxt={`$${new Intl.NumberFormat().format(
                              ((addedContribution * rateUSD) / 0.85) * 0.15,
                            )} USD`}
                            avatar={cirusIcon}
                            isIconMobile={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns card-container">
            <div className="column">
              <div className="card">
                <div className="">
                  <div className="columns m-0 level staking-option p-0">
                    <div className="column is-narrow p-0 level m-0">
                      <div className="field-label is-normal level staking-label is-narrow m-0">
                        <label className="level-item level-left title is-size-5 is-narrow">
                          I want to
                        </label>
                      </div>
                    </div>
                    <div className="column staking-option">
                      <div className="field is-horizontal mb-0">
                        <div className="field-body">
                          <div className="field staking-select">
                            <div className="control is-expanded">
                              <div
                                className={
                                  dropDownOpen
                                    ? 'is-active dropdown is-top-rounded is-rounded fixed-200'
                                    : 'dropdown is-rounded fixed-200'
                                }
                              >
                                <div className="dropdown-trigger">
                                  <button
                                    className="button is-medium is-justify-content-left is-rounded no-focus"
                                    aria-haspopup="true"
                                    aria-controls="dropdown-menu"
                                    onClick={(e) =>
                                      setDropDownOpen(!dropDownOpen)
                                    }
                                  >
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {action ? action : 'Select Action'}
                                    </span>
                                    <span className="icon is-small">
                                      <i
                                        className="fas fa-angle-down"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </button>
                                </div>
                                <div
                                  className="dropdown-menu is-rounded"
                                  id="dropdown-menu"
                                  role="menu"
                                >
                                  <div className="dropdown-content">
                                   {/* <a
                                      className="dropdown-item subtitle is-5"
                                      onClick={(e) => selectAction('stake')}
                                    >
                                      Stake
                                    </a> */}
                                    <hr className="dropdown-divider" />
                                    <a
                                      className="dropdown-item subtitle is-5"
                                      onClick={(e) => selectAction('Unstake')}
                                    >
                                      Unstake and claim rewards
                                    </a>
                                    {/* <hr className="dropdown-divider" />
                                    <a
                                      className="dropdown-item subtitle is-5"
                                      onClick={(e) =>
                                        selectAction(
                                          'Unstake and claim rewards',
                                        )
                                      }
                                    >
                                      Unstake and claim rewards
                                    </a> */}
                                    {/* <hr className="dropdown-divider" />
                                    <a
                                      className="dropdown-item subtitle is-5"
                                      onClick={(e) =>
                                        selectAction('Full Unstake')
                                      }
                                    >
                                      Full Unstake
                                    </a> */}
                                    <hr className="dropdown-divider" />
                                    <a
                                      className="dropdown-item subtitle is-5"
                                      onClick={(e) =>
                                        selectAction('Claim Reward')
                                      }
                                    >
                                      Claim Rewards only
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* desktop full button */}
                          </div>
                          <div className="field has-addons fixed-right-addon quantity-stake">
                            <p className="control">
                              <span className="button is-static is-large is-rounded">
                                <img
                                  src={cirusIcon}
                                  alt="Cirus Token"
                                  width="80"
                                />
                              </span>
                            </p>
                            <p className="control is-expanded">
                              {action == 'Claim Reward' ? (
                                <input
                                  disabled
                                  type="text"
                                  className="input is-medium is-rounded"
                                  placeholder="Amount"
                                  value={amountToStake}
                                  ref={input1Ref}
                                  onChange={(e) =>
                                    setAmountToStake(
                                      e.currentTarget.value.replace(
                                        /[^0-9.]+/g,
                                        '',
                                      ),
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="input is-medium is-rounded"
                                  placeholder="Amount"
                                  value={amountToStake}
                                  ref={input1Ref}
                                  onChange={(e) =>
                                    setAmountToStake(
                                      e.currentTarget.value.replace(
                                        /[^0-9.]+/g,
                                        '',
                                      ),
                                    )
                                  }
                                />
                              )}
                            </p>
                            {action == 'Claim Reward' ? (
                              <></>
                            ) : (
                              <button
                                className="staking full button is-outlined is-primary is-rounded is-hidden-mobile"
                                onClick={(e) => setAmount(100)}
                              >
                                Full
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* mobile full button */}
                      <div className="columns column pl-0 mt-0 ml-0 is-hidden-tablet">
                        <button
                          className="staking full button is-outlined is-primary is-rounded"
                          onClick={(e) => setAmount(100)}
                        >
                          Full
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div className="column level is-narrow want-to-stat">
                        <div className="level-left level-item">
                          <AmountBox
                            topTxt="Liquid Balance"
                            middleTxt={`${new Intl.NumberFormat().format(
                              liquid,
                            )} CIRUS`}
                            style={{ minWidth: '300px;' }}
                            bottomTxt={`$${new Intl.NumberFormat().format(
                              liquidUSD,
                            )} USD`}
                            avatar={liquidIcon}
                            isIconMobile={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns card-container">
            <div className="column">
              <div className="card has-no-horizontal-padding">
                <div className="card-content">
                  <div className="pl-6 pr-6">
                    <p className="title is-5 pb-0 mb-4">Review Details</p>
                    <p className="is-small">
                      Please make sure to review the amount and details
                      carefully.
                    </p>
                  </div>
                  <hr />
                  <div className="columns mt-6 pl-6 pr-6">
                    <div className="your-wallet column">
                      <p className="title is-6">
                        <small>
                          {action == 'stake'
                            ? 'From your wallet'
                            : 'To your wallet'}
                        </small>
                      </p>
                      <div className="your-wallet-info">
                        <img
                          src={metamaskIcon}
                          className="mr-3 ml-1"
                          alt="Metamask Icon"
                          width="32"
                        />
                        <span className="text-overflow-ellipsis">
                          {account ? account : 'Connect your account'}
                        </span>
                      </div>
                      {transactionError ? (
                        <p className="is-small is-danger is-link is-primary mt-5">
                          <strong>{transactionError}</strong>
                        </p>
                      ) : null}
                    </div>
                    <div className="choose-staker column ">
                      <p className="title is-6">
                        <small>
                          {action == 'stake'
                            ? 'To selected staking pool:'
                            : 'From selected staking pool'}
                        </small>
                      </p>
                      <div
                        className={
                          stakerDropDownOpen
                            ? 'is-active dropdown is-top-rounded is-rounded fixed-200'
                            : 'dropdown is-rounded fixed-200'
                        }
                      >
                        <div className="dropdown-trigger">
                          <button
                            className="button is-medium is-justify-content-left is-rounded no-focus"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu"
                            onClick={(e) =>
                              setStakerDropDownOpen(!stakerDropDownOpen)
                            }
                          >
                            <img
                              src={cirusIcon}
                              className="mr-3 ml-1"
                              alt="Cirus Icon"
                              width="32"
                            />
                            <span style={{ textTransform: 'capitalize' }}>
                              {stakerAction ? stakerAction : 'Select Action'}
                              {!stakerAction ? (
                                ''
                              ) : (
                                <>
                                  <br></br>
                                  <span style={{ fontSize: '12px' }}>
                                    {
                                      process.env
                                        .REACT_APP_STAKING_CONTRACT_ETHEREUM
                                    }
                                  </span>
                                </>
                              )}
                            </span>
                            <span className="icon is-small">
                              <i
                                className="fas fa-angle-down"
                                aria-hidden="true"
                              />
                            </span>
                          </button>
                        </div>
                        <div
                          className="dropdown-menu is-rounded"
                          id="dropdown-menu"
                          role="menu"
                        >
                          <div className="dropdown-content">
                            <a
                              className="dropdown-item subtitle is-5"
                              onClick={(e) =>
                                selectStakerAction('Cirus Community Staking')
                              }
                            >
                              Cirus Community Staking
                            </a>
                            {/* <hr className="dropdown-divider" />
                            <a
                              className="dropdown-item subtitle is-5 is-primary"
                              href="/staking/mantra-dao-staking"
                            >
                              <i className="fas fa-external-link-alt"></i>{' '}
                              Mantra Staking
                            </a> */}
                            {/* pull options from server/blockchain */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns level has-text-right mr-0 pr-5 pl-5">
                    <div className="column pr-3 mobile-alignment">
                      {action === 'Full Unstake' ||
                      action === 'Unstake and claim rewards' ||
                      action === 'Unstake' ? (
                        <p>
                          You are about to unstake your tokens. You will no
                          longer earn rewards on these tokens. <br />
                          By unstaking any amount of tokens, you will also be
                          claiming
                          <strong> all of your earned rewards</strong>
                        </p>
                      ) : action === 'Claim Reward' ? (
                        <span className="mt-4 ">
                          You are about to claim earned rewards. <br />
                          This will not affect your staked balance
                        </span>
                      ) : (
                        <span className="mt-4 ">
                          <strong className="cirus-red">15%</strong> of your
                          tokens will be contributed to the Cirus Ecosystem.
                          <br /> You will earn rewards on the remaining
                          <strong> 85%</strong>
                          <br />
                          <strong className="cirus-red">Note: </strong>
                          Increasing your stake will automatically claim all
                          staking rewards to your wallet.
                          <br />
                          <a
                            onClick={() => {
                              window.open(
                                'https://support.cirusfoundation.com/en/knowledge/cirus-token-hub#cirus-community-staking-pool',
                              );
                            }}
                          >
                            Learn More...
                          </a>
                        </span>
                      )}
                    </div>
                    <div className="column is-narrow no-border mobile-alignment">
                      <button
                        className="button is-primary is-medium is-rounded"
                        disabled={!action || !stakerAction ? true : false}
                        onClick={(e) => toggleTransactionModalActive()}
                      >
                        {action == 'Unstake' ||
                        action == 'Full Unstake' ||
                        action === 'Unstake and claim rewards' ? (
                          <span>Unstake</span>
                        ) : action == 'Claim Reward' ? (
                          <span>Claim Reward</span>
                        ) : (
                          <span>Confirm Stake</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isSignTransactionModalActive ? (
        <SignTransactionModal
          isActive={isSignTransactionModalActive}
          onClose={() => setIsSignTransactionModalActive(false)}
          stake={() => stake()}
          preApprove={() => preApprove()}
          contractApproved={contractApproved}
          isApproving={isApproving}
          withdraw={() => withdraw()}
          amountToStake={amountToStake}
          action={action}
        />
      ) : null}
      {isTransactionSuccessModalActive ? (
        <TransactionSuccessModal
          isActive={isTransactionSuccessModalActive}
          onClose={() => setIsTransactionSuccessModalActive(false)}
          wallet={account ? account : 'Connect your account'}
          tx={transactionHash}
        />
      ) : null}
      <Modal
        classes="transaction-modal-error"
        isActive={receivedTransactionError !== ''}
        onClose={() => setReceivedTransactionError('')}
      >
        <p className="title is-2 mt-6 mb-4 is-vcentered textCenter">
          Something Went Wrong
        </p>
        <p className="subtitle is-4 mt-3 mb-4 ">{receivedTransactionError}</p>
        <button
          className="button is-primary is-large is-rounded button-modal-error"
          onClick={() => setReceivedTransactionError('')}
        >
          Close
        </button>
      </Modal>
      {loading ? <LoadingModal /> : null}
    </>
  );
};

CirusStakingView.propTypes = {
  liquid: PropTypes.number,
  stakedContract: PropTypes.number,
  rewards: PropTypes.number,
  rewardsUSD: PropTypes.number,
  stakedContractUSD: PropTypes.number,
  setBalanceStakedContract: PropTypes.func,
  stakingContractTotalBalanceUSD: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  // eslint-disable-next-line no-undef
  ...bindActionCreators({ setBreadcrumbs }, dispatch),
}))(CirusStakingView);
